import React, { useState } from "react"
import { RadioInput } from "components/UI/RadioInput"
import { ModeType, VariantOptionWithId } from "typings/modules"
import { ColorOptionUI, MoreColorsButton } from "./ColorOptionUI"
import {
  Container,
  OptionWrapper,
  OptionInput,
} from "./ColorOptionSelector.styles"
import { SanityDefinedColor, SanityDefinedColorOrImage } from "typings/graphql"
import { isString } from "utils/stringUtils"
import { $ColorOptionsSize } from "./ColorOptionUI.styles"
import { Tooltip, tooltipCentered } from "components/UI/Tooltip/Tooltip"

export type SelectColorOption = {
  name: string
  swatchOrOpacityHex: string[] | SanityDefinedColorOrImage[]
  option?: VariantOptionWithId
  disabled?: boolean
}

type ColorSelectorProps = {
  colors: SelectColorOption[]
  selectedColorName: string
  onColorSelected: (color: SelectColorOption) => void
  mode?: ModeType
  showMoreByDefault?: boolean
} & $ColorOptionsSize

const SWATCHES_PER_ROW = 7

export const ColorSelector = ({
  colors,
  onColorSelected,
  mode,
  selectedColorName,
  $buttonSize = "small",
  showMoreByDefault = false,
}: ColorSelectorProps) => {
  const [showMore, setShowMore] = useState(showMoreByDefault)
  const [visibleColors, setVisibleColors] = useState<SelectColorOption[]>(
    showMore ? colors : colors.slice(0, SWATCHES_PER_ROW)
  )

  const hasMoreColors = colors.length > SWATCHES_PER_ROW && !showMore

  const handleClick = () => {
    setShowMore(true)
    setVisibleColors(colors)
  }

  const isStringArray = (val: any): val is string[] =>
    Array.isArray(val) && val.every(isString)

  return (
    <Container title="Choose product color">
      {visibleColors?.map(({ name, swatchOrOpacityHex, option, disabled }) => {
        const selected = name === selectedColorName
        const soldOutText = disabled ? `. ${name} is sold out` : ""
        const radioButtonLabel = selected
          ? `Selected color: ${name}${soldOutText}`
          : `Change color to ${name}${soldOutText}`
        return (
          <Tooltip
            key={`color-option-${name}`}
            position={tooltipCentered}
            label={name}
            $mode={mode}
          >
            <OptionWrapper $mode={mode}>
              <ColorOptionUI
                $buttonSize={$buttonSize}
                mode={mode}
                selected={selected}
                swatch={
                  isStringArray(swatchOrOpacityHex)
                    ? swatchOrOpacityHex.map(
                        hex =>
                          ({
                            opacityHex: hex,
                          } as SanityDefinedColor)
                      )
                    : (swatchOrOpacityHex as SanityDefinedColorOrImage[])
                }
                disabled={!!disabled}
              />
              <OptionInput>
                <RadioInput
                  title={radioButtonLabel}
                  name="color-option"
                  value={name}
                  onChange={() =>
                    onColorSelected({ name, swatchOrOpacityHex, option })
                  }
                  checked={name === selectedColorName}
                />
              </OptionInput>
            </OptionWrapper>
          </Tooltip>
        )
      })}
      {hasMoreColors && (
        <Tooltip
          key={"color-option-more-options"}
          position={tooltipCentered}
          label="Show more colors"
          $mode={mode}
        >
          <OptionWrapper $mode={mode} onClick={handleClick}>
            <MoreColorsButton
              $buttonSize={$buttonSize}
              mode={mode}
              selected={false}
            />
          </OptionWrapper>
        </Tooltip>
      )}
    </Container>
  )
}

import styled from "styled-components"
import { typography } from "styles"
import { Tooltip as ReachTooltip, Position } from "@reach/tooltip"
import "@reach/tooltip/styles.css"
import { $Mode } from "typings/modules"

export const tooltipCentered: Position = (targetRect, popoverRect) => {
  if (!targetRect || !popoverRect) return {}
  const triggerCenter = targetRect.left + targetRect.width / 2
  const left = triggerCenter - popoverRect.width / 2
  const maxLeft = window.innerWidth - popoverRect.width - 2
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: targetRect.top - 30 + window.scrollY,
  }
}

export const Tooltip = styled(ReachTooltip)<$Mode>`
  &[data-reach-tooltip] {
    ${typography.bodySmall};
    color: ${({ theme, $mode }) =>
      theme[$mode]?.swatch?.cursorHoverText?.opacityHex};
    background: ${({ theme, $mode }) =>
      theme[$mode]?.swatch?.cursorHoverBg?.opacityHex};
    padding: 0 12px;
    box-shadow: none;
    border: none;
  }
`

import React from "react"
import { Dropdown } from "components/UI/Dropdown/Dropdown"
import { DropdownItem } from "components/UI/Dropdown/Dropdown.styles"
import { getSelectedOptions } from "utils/productUtils"
import {
  MergedVariant,
  UpdateSelectedVariant,
  VarOptionsComProps,
} from "typings/modules"

type DropdownSelectorProps = {
  selectedVariant: MergedVariant
  updateSelectedVariant: UpdateSelectedVariant
} & VarOptionsComProps

export const DropdownSelector: React.FC<DropdownSelectorProps> = ({
  type,
  options,
  selectedVariant,
  updateSelectedVariant,
}) => {
  const selectedOptions = getSelectedOptions(selectedVariant)
  const selectedOptionName = options?.find(
    ({ option }) => option.id === selectedOptions[type]?.id
  )?.option?.value

  return (
    <Dropdown
      renderButton={selectedOptionName}
      renderList={options?.map(({ option }, i) => (
        <DropdownItem
          key={`variant-option-${i}`}
          onClick={() => {
            updateSelectedVariant(selectedVariant, {
              [type]: option,
            })
          }}
        >
          {option.value}
        </DropdownItem>
      ))}
    />
  )
}

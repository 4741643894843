import React from "react"
import styled from "styled-components"
import { Label } from "components/UI/Label"
import { typography } from "styles"
import { VisuallyHidden } from "components/UI/VisuallyHidden"

const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
`

const StyledLabel = styled(Label)`
  margin-bottom: 0;
`

const Value = styled.span`
  padding-left: 4px;
  color: ${({ theme }) => theme.dark?.text?.primary?.opacityHex};
  ${typography.bodySmall};
`

type Props = {
  label: string
  value: string
}

export const ColorDescriptor: React.FC<Props> = ({
  label,
  value,
  ...props
}) => {
  return (
    <Container {...props}>
      <VisuallyHidden>
        <label>{`Selected ${label}: ${value}`}</label>
      </VisuallyHidden>
      <StyledLabel aria-hidden="true">{`${label}:`}</StyledLabel>
      <Value aria-hidden="true">{value}</Value>
    </Container>
  )
}

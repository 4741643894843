import React from "react"
import styled from "styled-components"

type InputProps = {
  name: string
  title?: string
  value: string
  onChange: (value: string) => void
  checked: boolean
  disabled?: boolean
}

const Input = styled.input`
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
  }
`

export const RadioInput = (props: InputProps) => {
  const { name, title, value, onChange, checked, disabled } = props
  return (
    <Input
      data-test={`color-option-${value}`}
      type="radio"
      name={name}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value)
      }}
      checked={checked}
      disabled={disabled}
      title={title}
      aria-label={title}
    />
  )
}

import { Config } from "config/brands"
import { useEffect, useState } from "react"
import {
  SanityProductItem,
  ShopifyProductVariantConnection,
} from "typings/graphql"
import {
  ProductListItemType,
  MergedVariant,
  AvailableOptions,
  UpdateSelectedVariant,
  AvailableOptionPairs,
} from "typings/modules"
import { decodeVariantId } from "utils/decodeId"
import { getImage } from "utils/imageUtils"
import {
  areVariantTypesEqual,
  getAvailableOptions,
  getDefaultVariant,
  getVariantFromOptions,
  getVariantOptionPairs,
  mergeSanityShopifyVariants,
} from "utils/productUtils"
import { siteConstants } from "constants/site"

type VariantItem = ProductListItemType

export const getProductItemVariant = (
  item: SanityProductItem,
  variants: MergedVariant[],
  isSingleVariant: boolean
) => {
  return getDefaultVariant(
    variants,
    [item?.displayVariant?.variantId],
    isSingleVariant
  )
}

export const useVariantOptions = (item: VariantItem) => {
  const shopifyProduct = item.product
  const sanityProduct = item.item.product
  const shopifyVariants = ((shopifyProduct?.variants as unknown) as ShopifyProductVariantConnection)?.edges?.map(
    e => e?.node
  )
  const sanityVariants = sanityProduct?.variants
  const variantTypes = sanityProduct?.plpVariantTypes.length
    ? sanityProduct?.plpVariantTypes
    : sanityProduct?.variantTypes

  const [selectedVariant, setSelectedVariant] = useState<
    MergedVariant | undefined
  >(
    getProductItemVariant(
      item.item,
      sanityProduct?.variants as MergedVariant[],
      item.isSingleVariant
    )
  )
  const [availableOptions, setAvailableOptions] = useState<AvailableOptions>()
  const [
    selectableOptions,
    setSelectableOptions,
  ] = useState<AvailableOptionPairs | null>(null)
  const [variants, setVariants] = useState<MergedVariant[]>()

  // mergeSanityShopifyVariants
  useEffect(() => {
    if (!item?.product) return
    const mergedVariants = mergeSanityShopifyVariants(
      sanityVariants,
      shopifyVariants
    )
    const availableOptions = getAvailableOptions(mergedVariants, variantTypes)
    setVariants(mergedVariants)
    setAvailableOptions(availableOptions)
    setSelectedVariant(
      getProductItemVariant(item.item, mergedVariants, item.isSingleVariant)
    )
  }, [item.product])

  const updateSelectedVariant: UpdateSelectedVariant = (
    previousVariant,
    changedOption
  ) => {
    const newVariant = getVariantFromOptions(
      variants,
      previousVariant,
      changedOption
    )
    setSelectedVariant(newVariant)
  }

  useEffect(() => {
    if (!availableOptions || !selectedVariant || !variants) {
      return
    }

    let primaryVariantType =
      item?.item?.product?.primaryVariantType ||
      Config.defaultPrimaryVariantType

    primaryVariantType =
      variantTypes.find(vt => areVariantTypesEqual(vt, primaryVariantType)) ||
      variantTypes.find(vt =>
        areVariantTypesEqual(vt, siteConstants.fallbackPrimaryVariant)
      ) ||
      variantTypes[0]

    const allOptionPairs = Object.keys(availableOptions).reduce((acc, type) => {
      acc[type] = getVariantOptionPairs(
        variants,
        selectedVariant,
        type,
        availableOptions[type],
        primaryVariantType === type
      )

      return acc
    }, {} as AvailableOptionPairs)

    const optionPairs = item?.isSingleVariant
      ? (selectedVariant.selectedOptions.reduce(
          (pairs, currentPair) => ({
            ...pairs,
            [currentPair.name]: allOptionPairs[currentPair.name]?.filter(
              pair => pair.option.value === currentPair.value
            ),
          }),
          {}
        ) as AvailableOptionPairs)
      : allOptionPairs

    setSelectableOptions(optionPairs)
  }, [
    selectedVariant,
    variants,
    availableOptions,
    item?.item?.product?.primaryVariantType,
  ])

  const selectedVariantId = decodeVariantId(
    selectedVariant?.shopifyVariant?.variantId || ""
  )
  const selectedVariantTitle = `${sanityProduct?.title} - ${selectedVariant?.shopifyVariant?.title}`
  const image = getImage(selectedVariant?.image?.image)
  const imageAlt = `Image for ${selectedVariantTitle}`
  const hoverImage = getImage(selectedVariant?.hoverImage?.image)
  const hoverImageAlt = `Hover Image for ${selectedVariantTitle}`
  const waitlistSignupText = selectedVariant?.waitlistSignupText
  const disableWaitlist = selectedVariant?.disableWaitlist
  const additionalVariantDisplays = selectedVariant?.additionalVariantDisplays

  return {
    selectedVariantId,
    selectedVariant,
    image,
    imageAlt,
    hoverImage,
    hoverImageAlt,
    waitlistSignupText,
    disableWaitlist,
    variantTypes,
    selectableOptions,
    updateSelectedVariant,
    variants,
    additionalVariantDisplays,
  }
}

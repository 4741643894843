import { useStaticQuery, graphql } from "gatsby"
import { useQuery } from "@apollo/client"
import { shopifyApolloClient } from "config/apollo-clients/shopify"
import {
  SanityProduct,
  SanityProductItem,
  ShopifyProduct,
} from "typings/graphql"
import { Query } from "typings/graphql"
import { getProductById } from "graphql/queries/product"
import { useState, useEffect, useMemo } from "react"
import { ProductListItemType, ProductMetaVariantConn } from "typings/modules"
import { getDefaultVariant } from "utils/productUtils"
import { getYotpoIdsFromProduct, mergeReviews } from "utils/reviewUtils"
import { applyTagBasedDiscounts } from "utils/discountUtils"

export type Items =
  | (SanityProductItem | SanityProduct | null)[]
  | null
  | undefined

const query = graphql`
  query {
    allShopifyProduct {
      edges {
        node {
          id
          handle
          shopifyId: storefrontId
          images {
            id
            originalSrc
          }
        }
      }
    }
    allYotpoProduct {
      nodes {
        productId
        bottomline {
          averageScore
          totalOrganicReviews
          totalReview
          organicAverageScore
        }
      }
    }
  }
`

const isSanityProductArray = (items: any): items is SanityProduct[] => {
  return items?.every((item: any) => item?._type === "product")
}

const sanityProductToProductItem = (
  product: SanityProduct
): SanityProductItem => {
  return {
    title: product.title,
    description: product.description,
    product,
  } as SanityProductItem
}

export const useProductList = (products: Items) => {
  const items = isSanityProductArray(products)
    ? products.map(sanityProductToProductItem)
    : (products as SanityProductItem[])
  const { allYotpoProduct, allShopifyProduct } = useStaticQuery<Query>(query)
  const shopifyNodes = allShopifyProduct?.edges?.map(item => item?.node)
  const handles = items?.map(item => item?.product?.shopifyProduct?.handle)
  const shopifyProductNodes = shopifyNodes?.filter(product =>
    handles?.includes(product?.handle)
  )
  const ids = shopifyProductNodes?.map(product => product.shopifyId)
  const queryOptions = { variables: { ids }, client: shopifyApolloClient }
  const { data: shopifyData, loading } = useQuery<ProductMetaVariantConn>(
    getProductById,
    queryOptions
  )
  const dynamicData = useMemo(() => applyTagBasedDiscounts(shopifyData), [
    shopifyData,
  ])

  const [plItems, setPlItems] = useState<ProductListItemType[]>(
    items?.map((item, index) => ({
      item,
      product: undefined,
      variant: undefined,
      reviews: undefined,
      defaultOrder: index,
      isSingleVariant: false,
    })) || []
  )

  useEffect(() => {
    if (!loading) {
      const newItemsMap = items
        ?.map((item, index) => {
          const shopifyProduct = item?.product?.shopifyProduct
          const handle = shopifyProduct?.handle
          const product = dynamicData?.nodes?.find(
            node => node?.handle === handle
          )
          if (!product) {
            console.warn("No shopify product found for product item", item)
            return null
          }
          const variants = product?.variants
          const displayVariantId = item?.displayVariant?.variantId
          const defaultVariantId =
            item?.product?.variants?.[0].shopifyVariant?.variantId
          const variantNodes =
            variants?.edges?.map(variant => variant?.node) ?? []
          const displayVariant = variantNodes.find(
            variant => variant.id === displayVariantId
          )
          const defaultVariant = getDefaultVariant(variantNodes, [
            displayVariantId,
            defaultVariantId,
          ])
          const isSingleVariant = item?.renderSingleVariant
          const variant = isSingleVariant ? displayVariant : defaultVariant
          const yotpoIds = getYotpoIdsFromProduct(item?.product)
          const yotpoProducts = allYotpoProduct?.nodes?.filter(yotpoProduct =>
            yotpoIds.includes(yotpoProduct.productId)
          )

          const reviews = mergeReviews(yotpoProducts)

          return {
            item,
            product: (product as unknown) as ShopifyProduct,
            variant,
            reviews,
            defaultOrder: index,
            isSingleVariant,
          }
        })
        .filter(item => !!item?.product)

      const newItems = newItemsMap || []

      setPlItems(newItems)
    }
  }, [dynamicData?.nodes?.find, ...(products || []), loading])
  return {
    plItems,
    loading,
  }
}

import styled from "styled-components"
import { mq } from "styles"
import { $Mode } from "typings/modules"

export const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 4px;
  margin-left: -4px;
`

export const OptionWrapper = styled.div<$Mode>`
  position: relative;
  border: 1px solid transparent;

  ${mq.hasHover} {
    &:hover {
      div:first-of-type {
        border-color: ${({ theme, $mode }) =>
          theme[$mode]?.swatch?.hoverStroke?.opacityHex};
      }
    }
  }
`

export const OptionInput = styled.div`
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
`

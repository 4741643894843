import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { Disabled, $Selected, $Mode } from "typings/modules"
import DropdownSVG from "assets/svg/caret-down.svg"
import { mq } from "styles"

export type $ColorOptionsSize = {
  $buttonSize?: "large" | "small"
}

type $FilterSize = {
  $filterSize?: boolean
}

export const OptionUIExternalContainer = styled.div<
  $Selected & $Mode & $ColorOptionsSize & $FilterSize
>`
  width: 30px;
  height: 30px;
  padding: 2px;
  margin: 0 4px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ $selected, theme, $mode }) =>
    $selected ? theme[$mode]?.swatch?.activeStroke?.opacityHex : `transparent`};
  ${mq.maxWidth("md")} {
    margin: 0 3px;
  }
  ${({ $buttonSize }) =>
    $buttonSize === "small" &&
    `
    ${mq.minWidth("md")} {
      width: 24px;
      height: 24px;
      margin: 0 2px;
    }
  `}
  ${({ $filterSize }) =>
    $filterSize &&
    `
    width: 24px;
    height: 24px;
    margin: 0 2px;
  `}
`

const disabledStyles = css<$Mode & $ColorOptionsSize>`
  z-index: 1;
  content: "";
  width: 22px;
  height: 1px;
  background: ${({ theme, $mode }) =>
    theme[$mode]?.swatch?.activeStroke?.opacityHex};
  border-width: 1px 0;
  border-style: solid;
  border-color: ${({ theme, $mode }) =>
    theme[$mode]?.canvas?.primary?.opacityHex};
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  ${({ $buttonSize }) =>
    $buttonSize === "small" &&
    `
    ${mq.minWidth("md")} {
      width: 16px;
    }
  `}
`

export const OptionUIInternalContainer = styled.span<
  Disabled & $Mode & $ColorOptionsSize & $FilterSize
>`
  cursor: pointer;
  z-index: 1;
  width: 24px;
  height: 24px;
  display: block;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, $mode }) => theme[$mode]?.swatch?.fillStroke?.opacityHex};
  position: relative;
  &:before {
    ${({ disabled }) => disabled && disabledStyles}
  }
  ${({ $buttonSize: $pdpSize }) =>
    $pdpSize === "small" &&
    `
    ${mq.minWidth("md")} {
      width: 18px;
      height: 18px;
    }
  `}
  ${({ $filterSize }) =>
    $filterSize &&
    `
      width: 18px;
      height: 18px;
  `}
`

const swatchCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

export const ImageSwatch = styled(GatsbyImage)`
  ${swatchCss};
`

export const DualColor = styled.div<{
  $firstColor: string
  $secondColor: string
}>`
  ${swatchCss};
  background-image: ${({ $firstColor, $secondColor }) => `
    linear-gradient(${$firstColor}, ${$firstColor}),
    linear-gradient(${$secondColor}, ${$secondColor});
  `};
  background-size: 50% 100%, 100% 100%;
  background-repeat: no-repeat;
`

export const SingleColor = styled.div<{ $color: string }>`
  ${swatchCss};
  background-color: ${({ $color }) => $color};
`

export const ColorCaret = styled(DropdownSVG)<$Mode>`
  color: ${({ theme, $mode }) => theme[$mode]?.ui?.icon?.opacityHex};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
`

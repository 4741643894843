import { ColorDescriptor } from "components/ColorOptions/ColorDescriptor"
import { ColorOptionSelector } from "components/ColorOptions/ColorOptionSelector"
import { VariantSettingsContext } from "contexts/VariantSettingsContext"
import React, { useContext } from "react"
import {
  AvailableOptionPairs,
  MergedVariant,
  ModeType,
  UpdateSelectedVariant,
  VariantOptionWithId,
  VariantOptionWithIdPair,
} from "typings/modules"
import { getSelectedOptions } from "utils/productUtils"
import { DropdownSelector } from "./DropdownSelector"
import { LidsSelector } from "./LidsSelector"
import styled from "styled-components"
import { Label } from "components/UI/Label"
import { mq } from "styles"
import { RadioInput } from "components/UI/RadioInput/RadioInput"
import { VisuallyHidden } from "components/UI/VisuallyHidden"

type VariantOptionsProps = {
  mode?: ModeType
  config?: {
    swatch?: {
      renderLabel?: boolean
      buttonSize?: React.ComponentProps<
        typeof ColorOptionSelector
      >["$buttonSize"]
      showMoreByDefault?: React.ComponentProps<
        typeof ColorOptionSelector
      >["showMoreByDefault"]
    }
    radio?: {
      displayMode?: "textOptionType" | "radio"
    }
    dropdown?: {
      maxWidth?: string
      center?: boolean
    }
  }
  showLabels?: boolean
  showAltVariantDisplays?: boolean
  selectedVariant: MergedVariant
  updateSelectedVariant: UpdateSelectedVariant
  selectableOptions: AvailableOptionPairs
  renderOnlyVariantTypes?: (variantTypeName: string) => boolean
}

type AlternativeDisplayPairs = {
  [key: string]: VariantOptionWithIdPair[]
}

type DisplayTypes = "Color" | "Radio" | "Dropdown" | "Lids"

type AlternativeDisplayTypes = {
  [K in DisplayTypes]: AlternativeDisplayPairs
}

const Container = styled.div`
  > * {
    margin-bottom: 24px;

    ${mq.minWidth("lg")} {
      margin-bottom: 16px;
    }
  }
`

const DropdownWrapper = styled.div<{ $maxWidth?: string; $center?: boolean }>`
  max-width: ${({ $maxWidth }) => $maxWidth};
  margin: ${({ $center }) => ($center ? "0 auto" : undefined)};
`

export const VariantOptions: React.FC<VariantOptionsProps> = ({
  mode,
  selectedVariant,
  updateSelectedVariant,
  selectableOptions,
  config,
  renderOnlyVariantTypes = () => true,
  showLabels,
  showAltVariantDisplays,
}) => {
  const { shouldUseRadio, shouldUseDropdown, shouldUseSwatches } = useContext(
    VariantSettingsContext
  )
  const selectedOptions = getSelectedOptions(selectedVariant)

  const baseSelectableOptions: AvailableOptionPairs = {}
  const altSelectableOptions: AlternativeDisplayTypes = {
    Color: {},
    Radio: {},
    Dropdown: {},
    Lids: {},
  }

  if (selectableOptions) {
    Object.keys(selectableOptions)?.forEach((type: DisplayTypes) => {
      selectableOptions[type]?.forEach(option => {
        if (
          showAltVariantDisplays &&
          option?.optionVariant?.additionalVariantDisplays?.length
        ) {
          option.optionVariant?.additionalVariantDisplays?.forEach(
            ({ name }) => {
              if (altSelectableOptions[type][name]) {
                altSelectableOptions[type][name].push(option)
              } else {
                altSelectableOptions[type][name] = [option]
              }
            }
          )
        } else {
          if (baseSelectableOptions[type]) {
            baseSelectableOptions[type].push(option)
          } else {
            baseSelectableOptions[type] = [option]
          }
        }
      })
    })
  }

  return (
    <Container>
      {Object.keys(baseSelectableOptions || {})
        .filter(renderOnlyVariantTypes)
        .map((variantTypeName: DisplayTypes) => {
          const variantTypeComponent = {
            isDropdown: shouldUseDropdown(variantTypeName),
            isRadio: shouldUseRadio(variantTypeName),
            isSwatch: shouldUseSwatches(variantTypeName),
          }

          if (variantTypeComponent.isDropdown) {
            return (
              <>
                <DropdownWrapper
                  $maxWidth={config?.dropdown?.maxWidth}
                  $center={config?.dropdown?.center}
                >
                  {showLabels ? (
                    <>
                      <VisuallyHidden>
                        <label>
                          Selected {variantTypeName}:{" "}
                          {selectedOptions[variantTypeName].value}
                        </label>
                      </VisuallyHidden>
                      <Label aria-hidden="true">{variantTypeName}</Label>
                    </>
                  ) : null}
                  <DropdownSelector
                    type={variantTypeName}
                    options={baseSelectableOptions[variantTypeName]}
                    selectedVariant={selectedVariant}
                    updateSelectedVariant={updateSelectedVariant}
                  />
                </DropdownWrapper>
                {showAltVariantDisplays &&
                  Object.keys(altSelectableOptions.Dropdown).map(
                    altVariantType => (
                      <DropdownWrapper
                        key={altVariantType}
                        $maxWidth={config?.dropdown?.maxWidth}
                        $center={config?.dropdown?.center}
                      >
                        {showLabels ? (
                          <>
                            <VisuallyHidden>
                              <label>
                                {`Selected ${altVariantType}: ${selectedOptions?.[variantTypeName].value}`}
                              </label>
                            </VisuallyHidden>
                            <Label aria-hidden="true">{altVariantType}</Label>
                          </>
                        ) : null}
                        <DropdownSelector
                          type={variantTypeName}
                          options={
                            altSelectableOptions.Dropdown[altVariantType]
                          }
                          selectedVariant={selectedVariant}
                          updateSelectedVariant={updateSelectedVariant}
                        />
                      </DropdownWrapper>
                    )
                  )}
              </>
            )
          } else if (variantTypeComponent.isSwatch) {
            return (
              <>
                <div>
                  {showLabels || config?.swatch?.renderLabel ? (
                    <ColorDescriptor
                      label={variantTypeName}
                      value={
                        baseSelectableOptions[variantTypeName].some(
                          ({ option }) =>
                            option.value ===
                            selectedOptions?.[variantTypeName]?.value
                        )
                          ? selectedOptions?.[variantTypeName]?.value
                          : ""
                      }
                    />
                  ) : null}
                  <ColorOptionSelector
                    $buttonSize={config?.swatch?.buttonSize}
                    showMoreByDefault={config?.swatch?.showMoreByDefault}
                    type={variantTypeName}
                    options={baseSelectableOptions[variantTypeName]}
                    selectedVariant={selectedVariant}
                    updateSelectedVariant={updateSelectedVariant}
                    mode={mode}
                  />
                </div>
                {showAltVariantDisplays &&
                  Object.keys(altSelectableOptions.Color).map(
                    altVariantType => (
                      <div key={altVariantType}>
                        {showLabels || config?.swatch?.renderLabel ? (
                          <ColorDescriptor
                            label={altVariantType}
                            value={
                              altSelectableOptions[variantTypeName][
                                altVariantType
                              ].some(
                                ({ option }) =>
                                  option.value ===
                                  selectedOptions?.[variantTypeName]?.value
                              )
                                ? selectedOptions?.[variantTypeName]?.value
                                : ""
                            }
                          />
                        ) : null}
                        <ColorOptionSelector
                          $buttonSize={config?.swatch?.buttonSize}
                          showMoreByDefault={config?.swatch?.showMoreByDefault}
                          type={variantTypeName}
                          options={
                            altSelectableOptions[variantTypeName][
                              altVariantType
                            ]
                          }
                          selectedVariant={selectedVariant}
                          updateSelectedVariant={updateSelectedVariant}
                          mode={mode}
                        />
                      </div>
                    )
                  )}
              </>
            )
          } else {
            if (config?.radio?.displayMode === "radio") {
              const selectOption = (option: VariantOptionWithId) => (
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault()
                if (option.value) {
                  updateSelectedVariant(selectedVariant, {
                    [variantTypeName]: option,
                  })
                }
              }

              return (
                <>
                  <div>
                    {showLabels ? (
                      <>
                        <VisuallyHidden>
                          <label>
                            {`Selected ${variantTypeName}: ${selectedOptions?.[variantTypeName].value}`}
                          </label>
                        </VisuallyHidden>
                        <Label aria-hidden="true">{variantTypeName}</Label>
                      </>
                    ) : null}
                    <RadioInput
                      options={baseSelectableOptions[variantTypeName]}
                      selected={selectedOptions?.[variantTypeName]}
                      selectOption={selectOption}
                      optionName={variantTypeName}
                    />
                  </div>
                  {showAltVariantDisplays &&
                    Object.keys(altSelectableOptions.Radio).map(
                      altVariantType => (
                        <div key={altVariantType}>
                          {showLabels ? (
                            <>
                              <VisuallyHidden>
                                <label>
                                  {`Selected ${altVariantType}: ${selectedOptions?.[variantTypeName].value}`}
                                </label>
                              </VisuallyHidden>
                              <Label aria-hidden="true">{altVariantType}</Label>
                            </>
                          ) : null}
                          <RadioInput
                            options={altSelectableOptions.Radio[altVariantType]}
                            selected={selectedOptions?.[variantTypeName]}
                            selectOption={selectOption}
                            optionName={altVariantType}
                          />
                        </div>
                      )
                    )}
                </>
              )
            } else {
              return (
                <>
                  <LidsSelector
                    type={variantTypeName}
                    options={baseSelectableOptions[variantTypeName]}
                    selectedVariant={selectedVariant}
                    updateSelectedVariant={updateSelectedVariant}
                  />
                  {showAltVariantDisplays &&
                    Object.keys(
                      altSelectableOptions.Lids
                    ).map(altVariantType => (
                      <LidsSelector
                        key={altVariantType}
                        type={altVariantType}
                        options={
                          altSelectableOptions[variantTypeName][altVariantType]
                        }
                        selectedVariant={selectedVariant}
                        updateSelectedVariant={updateSelectedVariant}
                      />
                    ))}
                </>
              )
            }
          }
        })}
    </Container>
  )
}

export const ProductPageVariantOptions = (
  props: Omit<VariantOptionsProps, "showLabels" | "config">
) => (
  <VariantOptions
    {...props}
    showLabels
    config={{
      swatch: {
        buttonSize: "large",
        showMoreByDefault: true,
      },
      radio: {
        displayMode: "radio",
      },
    }}
    showAltVariantDisplays
  />
)

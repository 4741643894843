import React, { MouseEventHandler } from "react"
import {
  RadioContainer,
  RadioButton,
} from "components/UI/RadioInput/RadioInput.styles"
import { VariantOptionWithId, VariantOptionWithIdPair } from "typings/modules"
import { VisuallyHidden } from "components/UI/VisuallyHidden"

export type RadioVariants = { variant: "active" | "inactive" }
export type RadioOptionType = {
  name: string
  value: string
}

type RadioInputProps = {
  options: VariantOptionWithIdPair[]
  selected: VariantOptionWithId
  displayField?: keyof VariantOptionWithId
  selectOption?: (
    option: VariantOptionWithId
  ) => MouseEventHandler<HTMLButtonElement>
  optionName: string
}

export const RadioInput = ({
  options,
  selected,
  displayField = "value",
  selectOption,
  optionName,
}: RadioInputProps) => {
  return (
    <RadioContainer $count={options.length}>
      {options?.map((option: VariantOptionWithIdPair) => (
        <RadioInputButton
          key={`radio-${option.option[displayField]}`}
          option={option}
          selected={selected}
          displayField={displayField}
          selectOption={selectOption}
          optionName={optionName}
        />
      ))}
    </RadioContainer>
  )
}

type RadioInputButtonProps = {
  option: VariantOptionWithIdPair
  selected: VariantOptionWithId
  displayField?: keyof VariantOptionWithId
  selectOption?: (
    option: VariantOptionWithId
  ) => MouseEventHandler<HTMLButtonElement>
  optionName: string
}

const RadioInputButton = ({
  option,
  selected,
  displayField,
  selectOption,
  optionName,
}: RadioInputButtonProps) => {
  const opt = option.option
  const disabled = !option.optionVariant?.availableForSale
  const isWaitlist = !option.optionVariant?.disableWaitlist
  const active = opt?.id === selected?.id
  const value = opt[displayField]
  const soldOutText =
    disabled && active
      ? `. ${value} is sold out. ${
          isWaitlist ? `Sign up for waitlist below.` : ""
        }`
      : ""
  return (
    <div key={`option-${value}`}>
      <RadioButton
        type="button"
        variant={active ? "active" : "inactive"}
        onClick={selectOption(opt)}
        aria-label={
          active
            ? `Selected option ${value}${soldOutText}`
            : `Change ${optionName} to ${value}${soldOutText}`
        }
      >
        {value}
      </RadioButton>
      <VisuallyHidden aria-live="assertive">
        {disabled && active && (
          <>{`${value} is sold out.${
            isWaitlist && ` Sign up for waitlist below.`
          }`}</>
        )}
      </VisuallyHidden>
    </div>
  )
}

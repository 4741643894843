import React from "react"
import styled, { css } from "styled-components"
import { typography } from "styles"
import {
  MergedVariant,
  UpdateSelectedVariant,
  VarOptionsComProps,
} from "typings/modules"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { getSelectedOptions } from "utils/productUtils"
import { $Active, $Margin } from "typings/modules"

const activeStyles = css`
  border-color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
`

const inactiveStyles = css`
  &:hover {
    ${activeStyles}
  }
`

const LidsOption = styled(NoStyleButton)<$Active>`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  opacity: 1;
  transition: color 0.3s ease;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 5000px;
  border: 1px solid transparent;
  ${({ $active }) => ($active ? activeStyles : inactiveStyles)}
`

const Container = styled.span<$Margin>`
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 4px;
`

type LidsSelectorProps = {
  selectedVariant: MergedVariant
  updateSelectedVariant: UpdateSelectedVariant
} & VarOptionsComProps

export const LidsSelector: React.FC<LidsSelectorProps> = ({
  type,
  options,
  selectedVariant,
  updateSelectedVariant,
  ...props
}) => {
  const selectedOptions = getSelectedOptions(selectedVariant)

  return (
    <Container {...props}>
      {options?.map(({ option }, i) => (
        <LidsOption
          key={`variant-option-${i}`}
          $active={option.id === selectedOptions[type]?.id}
          onClick={() => {
            updateSelectedVariant(selectedVariant, {
              [type]: option,
            })
          }}
        >
          {option.value}
        </LidsOption>
      ))}
    </Container>
  )
}

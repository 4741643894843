import React from "react"
import "@reach/tooltip/styles.css"
import {
  VarOptionsComProps,
  ModeType,
  MergedVariant,
  UpdateSelectedVariant,
} from "typings/modules"
import { getSelectedOptions } from "utils/productUtils"
import { sortVariantOptionByAvailableForSale } from "utils/sortUtils"
import { ColorSelector } from "./ColorSelector"
import { $ColorOptionsSize } from "./ColorOptionUI.styles"

type ColorOptionSelectorProps = {
  selectedVariant: MergedVariant
  updateSelectedVariant: UpdateSelectedVariant
  mode?: ModeType
  showMoreByDefault?: boolean
} & VarOptionsComProps &
  $ColorOptionsSize

export const ColorOptionSelector = ({
  type,
  options,
  selectedVariant,
  updateSelectedVariant,
  mode = "dark",
  showMoreByDefault = false,
  $buttonSize,
}: ColorOptionSelectorProps) => {
  const selectedOptions = getSelectedOptions(selectedVariant)

  const sortedOptions = options
    .sort(sortVariantOptionByAvailableForSale)
    .map(({ option, optionVariant }) => ({
      name: option.value,
      swatchOrOpacityHex: optionVariant.swatch,
      option,
      disabled: !optionVariant.availableForSale,
    }))

  return (
    <ColorSelector
      $buttonSize={$buttonSize}
      showMoreByDefault={showMoreByDefault}
      mode={mode}
      colors={sortedOptions}
      selectedColorName={selectedOptions?.[type].value}
      onColorSelected={({ option }) => {
        updateSelectedVariant(selectedVariant, { [type]: option })
      }}
    />
  )
}

import styled from "styled-components"
import { animations, mq, typography } from "styles"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { RadioVariants } from "components/UI/RadioInput/RadioInput"

export const RadioContainer = styled.div<{ $count: number }>`
  ${typography.bodySmall};
  position: relative;
  z-index: 1;
  margin: 0 0 24px;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $count }) =>
    $count <= 4
      ? `repeat(auto-fit, minmax(calc(100% / ${$count}), 1fr));`
      : `repeat(auto-fit, minmax(25%, 1fr));`};
  cursor: pointer;
  div:only-child button {
    border-radius: var(--ui-rounding);
  }
  div:first-child button {
    border-radius: ${({ $count }) =>
      $count <= 4
        ? `var(--ui-rounding) 0 0 var(--ui-rounding)`
        : `var(--ui-truncated-rounding) 0 0 0`};
  }
  div:last-child button {
    border-radius: ${({ $count }) =>
      $count <= 4
        ? `0 var(--ui-rounding) var(--ui-rounding) 0`
        : `0 0 var(--ui-truncated-rounding) 0`};
  }
  ${({ $count }) =>
    $count > 4 &&
    `
      button:nth-child(4) {
        border-radius: 0 var(--ui-truncated-rounding) var(--ui-truncated-rounding) 0;
      }
      button:nth-last-of-type(${$count % 4}) {
        border-radius: 0 0 0 var(--ui-truncated-rounding);
      }
    `}
`

export const RadioButton = styled(NoStyleButton)<RadioVariants>`
  transition: all 300ms ${animations.defaultBezier};
  height: 48px;
  padding: 0 16px;
  margin: 0;
  width: 100%;
  ${({ variant }) =>
    `background: var(--radio-${variant}-fill);
    color: var(--radio-${variant}-text);
    border: var(--radio-${variant}-border);
    z-index: var(--radio-${variant}-z-index);`}
  ${mq.minWidth("lg")} {
    &:hover {
      ${({ variant }) =>
        `background: var(--radio-hover-fill);
        color: var(--radio-hover-text);
        border: var(--radio-${variant}-hover-border);`}
    }
  }
`

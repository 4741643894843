import React from "react"
import { ModeType } from "typings/modules"
import {
  DualColor,
  ImageSwatch,
  OptionUIExternalContainer,
  OptionUIInternalContainer,
  SingleColor,
  ColorCaret,
  $ColorOptionsSize,
} from "./ColorOptionUI.styles"
import {
  SanityDefinedColor,
  SanityDefinedColorOrImage,
  SanityImage,
} from "typings/graphql"
import { getImage } from "utils/imageUtils"

type Props = {
  filterSize?: boolean
  disabled?: boolean
  mode?: ModeType
  selected?: boolean
} & $ColorOptionsSize

type ColorOptionUIProps = Props & {
  swatch: SanityDefinedColorOrImage[]
} & $ColorOptionsSize

const ColorButton: React.FC<Props> = ({
  $buttonSize,
  filterSize,
  mode,
  selected,
  disabled,
  children,
  ...props
}) => (
  <OptionUIExternalContainer
    $buttonSize={$buttonSize}
    $filterSize={filterSize}
    $mode={mode}
    $selected={selected}
    {...props}
  >
    <OptionUIInternalContainer
      $buttonSize={$buttonSize}
      $filterSize={filterSize}
      $mode={mode}
      disabled={disabled}
    >
      {children}
    </OptionUIInternalContainer>
  </OptionUIExternalContainer>
)

export const ColorOptionUI: React.FC<ColorOptionUIProps> = ({
  $buttonSize,
  filterSize,
  swatch,
  disabled,
  selected,
  mode = "dark",
  ...props
}) => {
  const firstItem = swatch?.[0]
  const secondItem = swatch?.[1]
  const image =
    firstItem?.__typename === "SanityImage" &&
    getImage(firstItem as SanityImage)
  const firstColor = !image && (firstItem as SanityDefinedColor)?.opacityHex
  const secondColor =
    firstColor && secondItem && (secondItem as SanityDefinedColor)?.opacityHex
  return (
    <ColorButton
      $buttonSize={$buttonSize}
      filterSize={filterSize}
      mode={mode}
      selected={selected}
      disabled={disabled}
      {...props}
    >
      {image && (
        <ImageSwatch image={image?.gatsbyImage} alt="Pattern Swatch Image" />
      )}
      {secondColor && (
        <DualColor
          aria-label="Dual Color Swatch"
          $firstColor={firstColor}
          $secondColor={secondColor}
        />
      )}
      {!image && !secondColor && (
        <SingleColor
          role="img"
          aria-label="Single Color Swatch"
          $color={firstColor}
        />
      )}
    </ColorButton>
  )
}

export const MoreColorsButton = ({ $buttonSize, filterSize, mode }: Props) => (
  <ColorButton $buttonSize={$buttonSize} filterSize={filterSize} mode={mode}>
    <ColorCaret role="img" aria-label="Caret Icon" $mode={mode} />
  </ColorButton>
)

import { VariantOptionWithIdPair } from "typings/modules"

export const sortVariantOptionByAvailableForSale = (
  a: VariantOptionWithIdPair,
  b: VariantOptionWithIdPair
) => {
  return !a.optionVariant?.availableForSale && b.optionVariant?.availableForSale
    ? 1
    : !b.optionVariant?.availableForSale && a.optionVariant?.availableForSale
    ? -1
    : 0
}
